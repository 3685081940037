
.floating-label-content { 
    position:relative; 
    margin-top: 2rem; 
    margin-bottom: 1rem; 
}

.floating-label {
    color:#1e4c82; 
    font-size:13px;
    font-weight:normal;
    position:absolute;
    pointer-events:none;
    left:15px;
    top:11px;
    padding:0 5px;
    background:#fff;
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
}

textarea {
    resize: none !important;
    min-height: 20rem !important;
    min-width: 40rem !important;
}

.floating-input {
    font-size:1.5rem;
    display:block;
    width:100%;
    height:36px;
    padding: 0 20px;
    background: #fff;
    color: #323840;
    border: 1px solid #3D85D8;
    border-radius: 4px;
    box-sizing: border-box;
    &:focus{
        outline:none;
        ~ .floating-label{
        top:-1rem;
        font-size:13px;
        }
    }
}

.floating-input:not(:placeholder-shown) ~ .floating-label {
    top:-1rem;
    font-size:13px;
}

select.floating-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
select.floating-select::-ms-expand {
    display: none;
}

.floating-select:not([value=""]):valid ~ .floating-label {
    top:-1rem;
    font-size:13px;
}
.floating-select[value=""]:focus ~ .floating-label {
    top:11px;
    font-size:13px;
}
.floating-select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
    background-position: right 15px top 50%;
    background-repeat: no-repeat;
}

.did-error-input{
    .floating-input, .floating-select {
        border: 2px solid #9d3b3b;
        color:#9d3b3b;
    }
    .floating-label{
        font-weight: 600;
        color:#9d3b3b;
    }
    .floating-select:not([multiple]):not([size]) {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%239d3b3b'/%3E%3C/svg%3E%0A");
    }
}

.input-group {
    display: flex;
    .floating-input{
        border-radius:0 4px 4px 0;
        border-left:0;
        padding-left:0;
    }
}
.input-group-append {
    display:flex;
    align-items:center;
    /*   margin-left:-1px; */
    }
    .input-group-text {
        display: flex;
        align-items: center;
        font-weight: 400;
        height:34px;
        color: #323840;
        padding: 0 5px 0 20px;
    font-size:12px;
        text-align: center;
        white-space: nowrap;
        border: 1px solid #3D85D8;
    border-radius: 4px 0 0 4px;
    border-right:none;
}