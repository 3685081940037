form {
    display: block;
    position: relative;
    align-self: center;
}

.card {
    margin-top: 4rem;
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: .5rem;
    --bs-card-border-width: 1px;
    --bs-card-border-color: var(--bs-border-color-translucent);
    --bs-card-border-radius: .375rem;
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: calc(.375rem - 1px);
    --bs-card-cap-padding-y: .5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: rgba(0, 0, 0, .03);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: .75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 48rem;
    min-height: 20rem;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
    align-items: center;
}