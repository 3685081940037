.background {
    background: rgb(255 255 255 / 10%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.3px);
    -webkit-backdrop-filter: blur(5.3px);
    position: absolute;
    top: 0;
    margin-top: 1rem;
}

.background img {
    position: relative;
    top: 0;
    width: 12rem;
    height: 6rem;
}